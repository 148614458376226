import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { IAlert } from '@app/config/alert.interface';
import { AppSnackbarComponent } from '@shared/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class AlertToastService {

  constructor(
    private snackBar: MatSnackBar
  ) { }

  private showSnackbar(config: MatSnackBarConfig) {
    this.snackBar.openFromComponent(AppSnackbarComponent, config);
  }
  show(alert: IAlert) {
    this.showSnackbar({
      data: alert, 
      duration: 4000, 
      panelClass: ['p-0', alert.type === 'success' ? 'bg-success': `bg-danger`, alert.type === 'success' ? 'text-color-1':'text-color-4'], 
      direction: 'ltr',
      verticalPosition: 'top',
      horizontalPosition: 'right'
    })
  }
}
