import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { map } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'mars-admin';

  constructor(
    private router: Router
  ){
    this.router.events.pipe(
      map((x)=> x instanceof NavigationEnd)
    ).subscribe((res) => {
      if(res) {
        window.scroll({top: 0})
        document.getElementById('container')?.scrollTo({top: 0})
      }
    })
  }
}
