import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import { AuthService } from '../auth.service';
import { AlertToastService } from '../alert-toast.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private alertService: AlertToastService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let tokenReq: any;
    const token: any = this.authService.getToken();
    if (token) {
      tokenReq = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`, 'Cache-Control': 'no-cache',
        }
      });
    }
    else {
      if(request.headers.has('authorization')){
        let token: string = request.headers.get('authorization')!
        tokenReq = request.clone({
          setHeaders: {
              Authorization: token, 'Cache-Control': 'no-cache',
          }
        });
      } else{
        tokenReq = request.clone({
          setHeaders: {
              Authorization: `Basic cGlja3JpZ2h0LWlkOnBpY2tyaWdodC1zZWNyZXQ=`, 'Cache-Control': 'no-cache',
          }
        });
      }
    }

    return next.handle(tokenReq).pipe(
      map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
          }
          return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if(error.error && error.error.error && error.error.error.status === 403){
          this.alertService.show({message: 'Session Expired', type: 'danger'})
          this.authService.logout()
        }
        return throwError(error);
      })
    );
  }
}
